<template>
  <div class="statistics">
    <div class="statistics-container">
      <div class="header">
        <div class="heading-accent">
          {{ $t('statistics.title') }}
        </div>
      </div>

      <div class="card">
        <div class="form">
          <base-multiselect class="multiselect"
                            ref="stylists"
                            v-model="selectedStylists"
                            :options="stylists"
                            :multiple="true"
                            :label="$t('statistics.stylists.label')"
                            option-label="full_name"
                            track-by="id"
                            :placeholder="$t('statistics.stylists.searchPlaceholder')"
                            open-direction="bottom"
                            :close-on-select="false"
                            :loading="stylistsLoading"
                            :internal-search="false"
                            :clear-on-select="false"
                            :preserve-search="true"
                            :max-height="600"
                            @search-change="search">
            <template slot="option"
                      slot-scope="props">
              <div class="stylist">
                <base-avatar :user="props.option"/>
                <div class="full-name">
                  {{ props.option.full_name }}
                </div>
              </div>
            </template>
          </base-multiselect>

          <base-datepicker class="datepicker"
                           v-model="startDate"
                           :label="$t('statistics.period.startDate')"
                           :disabled-dates="{ from: endDate || new Date() }"
                           clear-button/>

          <base-datepicker class="datepicker"
                           v-model="endDate"
                           :label="$t('statistics.period.endDate')"
                           :disabled-dates="{ to: startDate, from: new Date() }"
                           clear-button/>
        </div>

        <base-select-tag v-model="selectedOrderType"
                         :options="$t('statistics.orderTypes')"
                         :label="$t('statistics.orderTypesLabel')">
        </base-select-tag>
        
        <base-button class="full-width"
                     :loading="loading"
                     @click="download">
          {{ $t('statistics.download') }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
  import profileService from '@/services/queries/profileQueries.js'
  import statisticsService from '@/services/queries/statisticsQueries.js'
  import moment from 'moment'

  export default {
    data() {
      return {
        stylistsFilters: {
          user_type: 'stylist',
          search_text: ''
        },
        stylists: [],
        selectedStylists: [],
        stylistsLoading: false,
        startDate: null,
        endDate: null,
        selectedOrderType: this.$t('statistics.orderTypes').find(type => type.value === 'ALL'),
        loading: false
      }
    },

    metaInfo() {
      return {
        title: this.$t('statistics.pageTitle')
      }
    },

    created() {
      this.getStylists()
    },

    methods: {
      getStylists() {
        this.stylistsLoading = true

        return profileService.get(this.stylistsFilters).then(response => {
          this.stylists = response.items

          this.stylistsLoading = false
        })
      },

      search: _.debounce(function(query) {
        this.stylistsFilters.search_text = query

        this.getStylists()
      }, 500),

      download() {
        this.loading = true

        statisticsService.get(this.getStatisticsFilters()).then(response => {
          window.location.href = response.result_url

          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      },

      getStatisticsFilters() {
        let filters = {}

        if (this.startDate) {
          filters['time_created.min'] = this.getFormattedDate(this.startDate)
        }

        if (this.endDate) {
          filters['time_created.max'] = this.getFormattedDate(this.endDate)
        }

        if (this.selectedStylists.length) {
          filters['stylist_id'] = this.getFormattedStylistList()
        }

        if (this.selectedOrderType.value !== 'ALL') {
          filters['task_type'] = this.selectedOrderType.value
        }

        return filters
      },

      getFormattedDate(date) {
        return moment(date).format('YYYY-MM-DD')
      },

      getFormattedStylistList() {
        let formattedStylistList = ''

        this.selectedStylists.forEach((stylist, index) => {
          formattedStylistList += stylist.id

          if (index === this.selectedStylists.length - 1) {
            return
          }

          formattedStylistList += ','
        })

        return formattedStylistList
      }
    }
  }
</script>

<style lang="scss" scoped>
  .statistics {
    display: flex;
    justify-content: center;
    background-color: #F8F8F8;

    .statistics-container {
      width: 100%;
      max-width: 500px;
      padding: 30px;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      .heading-accent {
        margin: 0;
      }
    }

    .form {
      margin-bottom: 40px;
    }

    .multiselect,
    .datepicker {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    .stylist {
      display: flex;
      align-items: center;

      .full-name {
        margin-left: 10px;
      }
    }
  }
</style>