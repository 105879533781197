const baseURL = 'crm/statistics'

export default {
  /**
   * Get statistics.
   *
   * @param filters
   * @returns Promise
   */
  get(params = {}) {
    return axios.get(baseURL, { params }).then(response => response.data)
  }
}